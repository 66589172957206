// /src/pages/request-storage/RequestStorage.page.tsx
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Notification, Stack, Text, Title } from '@mantine/core';

const RequestStorage: React.FC = () => {
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);

  const checkAndRequestStorage = async () => {
    try {
      if ('requestStorageAccess' in document) {
        await document.requestStorageAccess();
        navigate(import.meta.env.VITE_APP_SSO); // Redirect to sso for authorization
      } else {
        setError('Your browser does not support the Storage Access API. Please enable cookies.');
      }
    } catch (err) {
      setError(
        err instanceof Error
          ? err.message
          : 'An unknown error occurred while requesting storage access.'
      );
    }
  };

  useEffect(() => {
    checkAndRequestStorage();
  }, []);

  return (
    <Stack
      align="center"
      justify="center"
      gap="lg"
      style={{ height: '100vh', textAlign: 'center' }}
    >
      <Title>Storage Access Required</Title>
      <Text>
        To use this application, storage access must be enabled. This allows us to securely store
        session information.
      </Text>
      {error && (
        <Notification color="red" title="Error" onClose={() => setError(null)}>
          {error}
        </Notification>
      )}
      <Button onClick={checkAndRequestStorage}>Request Storage Access</Button>
    </Stack>
  );
};

export default RequestStorage;
