// src/pages/dashboard/subaccount/SubAccountDashboard.page.tsx
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DashboardLayout from '@/components/Layout/DashboardLayout';

const SubAccountLayout: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const menuItems = [
    { label: 'Home', path: '/sub-account-dashboard/dashboard' },
    { label: 'Products', path: '/sub-account-dashboard/products' },
    { label: 'Billing', path: '/sub-account-dashboard/billing' },
    { label: 'Settings', path: '/sub-account-dashboard/settings' },
  ];

  const _tabs = [
    { label: 'Dashboard', path: '/sub-account-dashboard' },
    { label: 'Reports', path: '/sub-account-dashboard/reports' },
  ];

  useEffect(() => {
    // Redirect to 'Home' only if user is on the base layout route
    if (location.pathname === '/sub-account-dashboard') {
      navigate('/sub-account-dashboard/dashboard', { replace: true });
    }
  }, [location.pathname, navigate]);

  // return <DashboardLayout menuItems={menuItems} tabs={tabs} />;
  return <DashboardLayout menuItems={menuItems} />;
};

export default SubAccountLayout;
