// src/pages/dashboard/agency/AgencyDashboard.page.tsx
import React from 'react';
import DashboardLayout from '@/components/Layout/DashboardLayout';

const AgencyDashboard: React.FC = () => {
  const menuItems = [
    { label: 'Home', path: '/agency-dashboard' },
    { label: 'Products', path: '/agency-dashboard/products' },
    { label: 'Billing', path: '/agency-dashboard/billing' },
    { label: 'Settings', path: '/agency-dashboard/settings' },
  ];

  const _tabs = [
    { label: 'Dashboard', path: '/agency-dashboard' },
    { label: 'Reports', path: '/agency-dashboard/reports' },
  ];

  return <DashboardLayout menuItems={menuItems} />;
};

export default AgencyDashboard;
