// src/pages/dashboards/agency/AgencyProducts.page.tsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Stack, Title } from '@mantine/core';
import ProductGallery from '@/components/Products/ProductGallery';

const AgencyProductsPage: React.FC = () => {
  const navigate = useNavigate();

  const handleNavigateToEdit = () => {
    navigate('/products/edit');
  };

  return (
    <Stack gap="lg" p="lg">
      <Title order={2}>Agency Products</Title>
      <Button onClick={handleNavigateToEdit} size="md" radius="xl" variant="filled">
        Create Postcard
      </Button>
      {/* Product Gallery Component */}
      <ProductGallery />
    </Stack>
  );
};

export default AgencyProductsPage;
