// /src/interfaces/SSOSession.ts

export enum SSOSessionType {
  AGENCY = 'agency',
  SPOOFED_ACCOUNT = 'spoofed_account',
  ACCOUNT = 'account',
}

export interface SSOSession {
  userId: string;
  companyId: string;
  locationId: string | null;
  role: string;
  sessionType: SSOSessionType | null;
}
