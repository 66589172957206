// /src/store/sessionSlice.ts
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SSOSession } from '@/interfaces/SSOSession';
import { fetchSessionApi } from '@/services/ssoService';

// Thunk to fetch session and handle Redux actions
export const fetchSession = createAsyncThunk(
  'session/fetchSession',
  async (_, { rejectWithValue }) => {
    try {
      const sessionData: SSOSession = await fetchSessionApi();
      return sessionData;
    } catch (error: any) {
      return rejectWithValue(error.message || 'Failed to fetch session');
    }
  }
);

interface SessionState {
  data: SSOSession;
  loading: boolean;
  error: string | null;
}

const initialState: SessionState = {
  data: {
    userId: '',
    companyId: '',
    locationId: null,
    role: '',
    sessionType: null,
  },
  loading: false,
  error: null,
};

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    clearSession: (state) => {
      state.data = initialState.data;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSession.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSession.fulfilled, (state, action: PayloadAction<SessionState['data']>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchSession.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { clearSession } = sessionSlice.actions;
export default sessionSlice.reducer;
