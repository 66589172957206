// /src/services/agencyService.ts
import {
  ApiResponse,
  ProductResponse,
  SearchProductsParams,
  SearchProductsResponse,
} from '@/interfaces/Api';
import { Product } from '@/interfaces/Product';
import { Billing } from '@/models/Billing';
import { InstalledLocation, InstalledLocationRaw } from '@/models/Location';
import { api } from './apiService';

/**
 * Fetches agency locations and maps response to InstalledLocation format.
 */
export async function fetchAgencyLocations(): Promise<InstalledLocation[]> {
  try {
    const response = await api.get<{ data: InstalledLocationRaw[] }>('/agency/locations');

    return response.data.data.map((location) => ({
      id: location._id, // Rename `_id` to `id`
      address: location.address,
      name: location.name,
      isInstalled: location.isInstalled,
      trial: {
        onTrial: location.trial.onTrial,
      },
    }));
  } catch (error) {
    console.error('Error fetching agency locations:', error);
    throw error;
  }
}

/**
 * Interface for customer balance response from Stripe.
 */
interface CustomerBalance {
  id: string;
  name: string;
  address: { line1: string; city: string; state: string; postal_code: string } | null;
  email: string;
  balance: number;
}

interface FetchAllStripeCustomersResponse {
  customer_balances: CustomerBalance[];
}

/**
 * Fetches all Stripe customers with their balances.
 */
export async function fetchAllStripeCustomers(): Promise<CustomerBalance[]> {
  try {
    const response = await api.get<FetchAllStripeCustomersResponse>('/agency/customers/balances');
    return response.data.customer_balances;
  } catch (error) {
    console.error('Error fetching Stripe customer balances:', error);
    throw error;
  }
}

/**
 * Fetches all billing data from the agency.
 */
export async function fetchBillingData(): Promise<Billing[]> {
  try {
    const response = await api.get<{ data: Billing[] }>('/agency/billing/all');
    return response.data.data ?? []; // Ensure always returns an array
  } catch (error) {
    console.error('Error fetching billing data:', error);
    throw error;
  }
}

/**
 * Saves or updates billing information.
 */
interface BillingUpdateRequest {
  location_id: string;
  stripe_customer_id: string | null;
  balance_minimum?: number;
  balance_maximum?: number;
}

export async function updateBillingInfo(billingData: BillingUpdateRequest): Promise<ApiResponse> {
  try {
    const response = await api.post<ApiResponse>('/agency/billing/save', billingData);
    return response.data;
  } catch (error) {
    console.error('Error saving billing information:', error);
    throw error;
  }
}

/**
 * Submits or updates a product using a FormData payload.
 */
export const submitOrUpdateProduct = async (formData: FormData): Promise<ProductResponse> => {
  try {
    const response = await api.post<ApiResponse<Product>>('/products/create', formData, {
      headers: { 'Content-Type': 'multipart/form-data' }, // Required for FormData
    });

    return response.data;
  } catch (error) {
    console.error('Error submitting or updating product:', error);
    throw error;
  }
};

/**
 * Searches for products using optional query parameters.
 */
export const searchProducts = async (
  params: SearchProductsParams = {}
): Promise<SearchProductsResponse> => {
  try {
    // Convert params object to query string
    const queryString = new URLSearchParams(
      Object.entries(params)
        .filter(([, value]) => value !== undefined) // Remove undefined values
        .map(([key, value]) => [key, String(value)]) // Ensure all values are strings
    ).toString();

    // Make API request with query parameters
    const response = await api.get<SearchProductsResponse>(`/products/search?${queryString}`);

    return response.data;
  } catch (error) {
    console.error('Error searching products:', error);
    throw error;
  }
};

/**
 * Fetches a product by ID, returning null if not found (404).
 */
export const getProductById = async (productId: string): Promise<Product | null> => {
  try {
    const response = await api.get<ProductResponse>(`/products/${productId}`);
    return response.data.data ?? null;
  } catch (error: any) {
    if (error.response?.status === 404) {
      console.warn(`Product with ID ${productId} not found.`);
      return null; // Return null for 404 errors
    }
    console.error('Error fetching product by ID:', error);
    throw error;
  }
};

/**
 * Archive a product by ID, returning null if not found (404).
 */
export const archiveProductById = async (productId: string): Promise<ApiResponse | null> => {
  try {
    const response = await api.patch<ApiResponse>(`/products/${productId}/archive`);
    console.log('Archived product:', productId);
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 404) {
      console.warn(`Product with ID ${productId} not found.`);
      return null; // Return null for 404 errors
    }
    console.error('Error archiving product:', error);
    throw error;
  }
};

/**
 * Configures API keys for all sub-accounts under an agency.
 */
export async function configureAgency(): Promise<ApiResponse> {
  try {
    const response = await api.post<ApiResponse>('/agency/configure');
    return response.data;
  } catch (error) {
    console.error('Error configuring agency:', error);
    throw error;
  }
}
