// src/constants/productConstants.ts

// Define the product size type
export type ProductSize = '4x6' | '6x9' | '6x11';

// Centralize product sizes as an array of objects for UI and validation
export const PRODUCT_SIZES: Array<{ value: ProductSize; label: string }> = [
  { value: '4x6', label: '4x6' },
  { value: '6x9', label: '6x9' },
  // { value: '6x11', label: '6x11' },
];

// Extract product size values for validation purposes
export const PRODUCT_SIZE_VALUES: ProductSize[] = PRODUCT_SIZES.map((size) => size.value);
