//import { useNavigate } from 'react-router-dom';
import { IconHeart, IconRocket, IconUsers } from '@tabler/icons-react';
import { Box, Button, Card, Container, Grid, Group, Text, Title } from '@mantine/core';

const Home = () => {
  //const navigate = useNavigate();

  const handleGetStarted = () => {
    window.location.href = 'https://postpalooza.com/api/v1/installs/senddirectmail';
  };

  return (
    <Box>
      {/* Hero Section */}
      <Box
        style={{
          position: 'relative',
          textAlign: 'center',
          height: '80vh',
          background: 'url(https://placehold.co/1920x600) center/cover no-repeat',
        }}
      >
        <Container size="md" style={{ position: 'relative', zIndex: 2, top: '30%' }}>
          <Title
            order={1}
            size="h1"
            style={{
              fontWeight: 800,
              color: 'white',
              textAlign: 'center',
            }}
          >
            Welcome to RunIt Postcards
          </Title>
          <Text
            size="lg"
            c="dimmed"
            style={{
              marginTop: '1rem',
              textAlign: 'center',
            }}
          >
            Effortless direct mail automation, designed for your success
          </Text>
          <Button
            size="xl"
            radius="xl"
            mt="lg"
            onClick={handleGetStarted}
            style={{ backgroundColor: '#007bff', color: '#fff' }}
          >
            Get Started
          </Button>
        </Container>
      </Box>

      {/* Features Section */}
      <Container size="lg" py="xl">
        <Title
          order={2}
          size="h2"
          textWrap="wrap"
          style={{
            textAlign: 'center',
            marginTop: '2rem',
            marginBottom: '1rem',
          }}
        >
          Why Choose PostPalooza?
        </Title>
        <Text
          style={{
            color: '#6b7280',
            textAlign: 'center',
            marginBottom: '2rem',
          }}
        >
          Discover the features that make us the best in the business.
        </Text>
        <Grid gutter="md">
          <Grid.Col span={4}>
            <Card shadow="sm" p="lg">
              <IconRocket size={40} color="blue" />
              <Text fw={700} mt="md">
                Lightning-Fast Delivery
              </Text>
              <Text c="dimmed" size="sm" mt="xs">
                Your postcards delivered quickly and reliably every time.
              </Text>
            </Card>
          </Grid.Col>
          <Grid.Col span={4}>
            <Card shadow="sm" p="lg">
              <IconUsers size={40} color="green" />
              <Text fw={700} mt="md">
                User-Friendly Design
              </Text>
              <Text c="dimmed" size="sm" mt="xs">
                An interface so intuitive, anyone can use it.
              </Text>
            </Card>
          </Grid.Col>
          <Grid.Col span={4}>
            <Card shadow="sm" p="lg">
              <IconHeart size={40} color="red" />
              <Text fw={700} mt="md">
                Loved by Customers
              </Text>
              <Text c="dimmed" size="sm" mt="xs">
                Join thousands of satisfied users making an impact.
              </Text>
            </Card>
          </Grid.Col>
        </Grid>
      </Container>

      {/* Testimonials Section */}
      <Box style={{ background: '#f8f9fa' }} py="xl">
        <Container size="lg">
          <Title
            order={2}
            size="h2"
            textWrap="wrap"
            style={{
              textAlign: 'center',
              marginTop: '2rem',
              marginBottom: '1rem',
            }}
          >
            What Our Users Say
          </Title>
          <Grid gutter="md">
            <Grid.Col span={6}>
              <Card shadow="sm" p="lg">
                <Text fw={700}>John Doe</Text>
                <Text c="dimmed" size="sm" mt="xs">
                  "PostPalooza transformed how we handle direct mail, making it simple and
                  efficient."
                </Text>
              </Card>
            </Grid.Col>
            <Grid.Col span={6}>
              <Card shadow="sm" p="lg">
                <Text fw={700}>Jane Smith</Text>
                <Text c="dimmed" size="sm" mt="xs">
                  "I love how easy it is to set up and send out campaigns. Highly recommend!"
                </Text>
              </Card>
            </Grid.Col>
          </Grid>
        </Container>
      </Box>

      {/* Footer */}
      <Box style={{ background: '#343a40', color: '#fff' }} py="md">
        <Container size="lg">
          <Group justify="space-between" align="center">
            <Text size="sm">© 2024 PostPalooza. All rights reserved.</Text>
            <Group gap="md">
              <Text size="sm">Privacy Policy</Text>
              <Text size="sm">Terms of Service</Text>
            </Group>
          </Group>
        </Container>
      </Box>
    </Box>
  );
};

export default Home;
