// /src/main.tsx
import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom/client';
import App from './App';

Sentry.init({
  dsn: 'https://ad3dc86110fb47ae96a63e717a89b163@o4508581260034048.ingest.us.sentry.io/4508581264621568',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/postpalooza\.com\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById('root')!).render(<App />);
