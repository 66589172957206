// /src/components/SubAccountApiKey/SubAccountApiKey.tsx
import React, { useEffect, useState } from 'react';
import { IconCopy, IconEye, IconEyeOff, IconRefresh } from '@tabler/icons-react';
import { ActionIcon, Button, Code, Group, Modal, Switch, Text, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import {
  fetchApiKey,
  refreshApiKey as refreshBackendApiKey,
  setApiKeyTestMode,
} from '@/services/subaccountService';

interface SubAccountApiKeyProps {
  locationId: string | null;
}

const SubAccountApiKey: React.FC<SubAccountApiKeyProps> = ({ locationId }) => {
  const [apiKey, setApiKey] = useState<string | null>(null);
  const [testMode, setTestMode] = useState<boolean>(false);
  const [_loading, setLoading] = useState(false);
  const [apiKeyVisible, { toggle: toggleApiKeyVisibility }] = useDisclosure(false);
  const [modalOpened, { open: openModal, close: closeModal }] = useDisclosure(false);

  const handleCopyToClipboard = () => {
    if (apiKey) {
      navigator.clipboard.writeText(apiKey);
      notifications.show({
        title: 'Success',
        message: 'API key copied to clipboard',
        color: 'green',
      });
    }
  };

  const loadApiKey = async () => {
    if (!locationId) {
      console.error('Location ID is missing in session data.');
      return;
    }
    setLoading(true);

    try {
      const response = await fetchApiKey();
      if (response.data) {
        setApiKey(response.data.api_key);
        setTestMode(response.data.test_mode);
      } else {
        notifications.show({
          title: 'Warning',
          message: 'No API key found',
          color: 'yellow',
        });
      }
    } catch (error: any) {
      notifications.show({
        title: 'Error',
        message: error.message || 'Failed to fetch API key',
        color: 'red',
      });
    } finally {
      setLoading(false);
    }
  };

  const refreshApiKey = async () => {
    if (!locationId) {
      console.error('Location ID is missing in session data.');
      return;
    }
    setLoading(true);

    try {
      const response = await refreshBackendApiKey(locationId);
      if (response.data) {
        setApiKey(response.data.api_key);
        notifications.show({
          title: 'Success',
          message: 'API key has been refreshed successfully',
          color: 'green',
        });
      } else {
        notifications.show({
          title: 'Warning',
          message: 'No API key found in response',
          color: 'yellow',
        });
      }
    } catch (error: any) {
      notifications.show({
        title: 'Error',
        message: error.message || 'Failed to refresh API key',
        color: 'red',
      });
    } finally {
      setLoading(false);
      closeModal();
    }
  };

  const handleToggleTestMode = async (value: boolean) => {
    try {
      setTestMode(value); // Optimistic update
      await setApiKeyTestMode(value);
      notifications.show({
        title: 'Success',
        message: `Test mode ${value ? 'enabled' : 'disabled'}`,
        color: 'green',
      });
    } catch (error: any) {
      notifications.show({
        title: 'Error',
        message: error.message || 'Failed to toggle test mode',
        color: 'red',
      });
      setTestMode(!value); // Revert on failure
    }
  };

  useEffect(() => {
    loadApiKey();
  }, [locationId]);

  return (
    <>
      <Group justify="space-between" w="100%" align="center">
        {/* Left: API Key text + actions */}
        <Group align="center" justify="flex-start">
          <Text>API Key:</Text>
          <Group gap="xs">
            <Text onClick={handleCopyToClipboard} style={{ cursor: 'pointer', userSelect: 'none' }}>
              <Code p={10}>{apiKeyVisible ? apiKey : '•'.repeat(apiKey ? apiKey.length : 16)}</Code>
            </Text>
            <Tooltip label={apiKeyVisible ? 'Hide API Key' : 'Show API Key'}>
              <ActionIcon onClick={toggleApiKeyVisibility}>
                {apiKeyVisible ? <IconEyeOff size={18} /> : <IconEye size={18} />}
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Copy API Key">
              <ActionIcon onClick={handleCopyToClipboard}>
                <IconCopy size={18} />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Refresh API Key">
              <ActionIcon onClick={openModal}>
                <IconRefresh size={18} />
              </ActionIcon>
            </Tooltip>
          </Group>
        </Group>

        {/* Right: Test Mode Toggle */}
        <Switch
          label="Test Mode"
          checked={testMode}
          onChange={(event) => handleToggleTestMode(event.currentTarget.checked)}
        />
      </Group>

      {/* Modal floats independently */}
      <Modal opened={modalOpened} onClose={closeModal} title="Confirm API Key Refresh" centered>
        <Text>
          Are you sure you want to refresh the API key? This could lead to potential loss of data.
        </Text>
        <Group mt="md" justify="flex-end">
          <Button variant="default" onClick={closeModal}>
            Cancel
          </Button>
          <Button color="red" onClick={refreshApiKey}>
            Yes, Refresh API Key
          </Button>
        </Group>
      </Modal>
    </>
  );
};

export default SubAccountApiKey;
