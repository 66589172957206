// /src/services/ssoService.ts
import { ApiResponse } from '@/interfaces/Api';
import { SSOSession } from '@/interfaces/SSOSession';
import { api, deleteAccessToken } from './apiService';

export async function fetchSessionApi(): Promise<SSOSession> {
  console.log(`Fetching session from ${import.meta.env.VITE_SSO_AUTH_URL}`);

  try {
    const response = await api.get<ApiResponse<SSOSession>>(import.meta.env.VITE_SSO_AUTH_URL);
    console.log('RESPONSE :', response);
    // Ensure session data is valid
    if (response.status !== 200 || !response.data.data) {
      throw new Error('Session authentication failed: Invalid session data.');
    }

    return response.data.data;
  } catch (error: any) {
    console.error('Error fetching session:', error);

    if (error.response?.status === 401) {
      sessionStorage.removeItem('access_token');
      throw new Error('Session invalidated. Unauthorized access.');
    }

    throw new Error(
      `Failed to authenticate session: ${error.response?.status} ${error.response?.statusText}`
    );
  }
}

export async function requestSSOKey() {
  return new Promise<string>((resolve, reject) => {
    deleteAccessToken(); // remove session token

    window.parent.postMessage({ message: 'REQUEST_USER_DATA' }, '*');

    const timeout = setTimeout(() => reject(new Error('SSO response timed out')), 10000);

    const listener = (event: MessageEvent) => {
      //if (event.origin !== import.meta.env.VITE_GHL_APP_ORIGIN) {return;}
      if (event.data.message === 'REQUEST_USER_DATA_RESPONSE') {
        clearTimeout(timeout);
        window.removeEventListener('message', listener);
        resolve(event.data.payload);
      }
    };

    window.addEventListener('message', listener);
  });
}

/**
 * Validates the SSO key and retrieves an access token.
 */
export async function validateSSOKey(ssoKey: string): Promise<void> {
  console.log(`Fetching ${import.meta.env.VITE_SSO_URL}`);

  try {
    const response = await api.post<{ access_token: string }>(
      import.meta.env.VITE_SSO_URL,
      { key: ssoKey },
      { headers: { Accept: 'application/json' } } // Override headers for non-authenticated requests
    );

    if (!response.data.access_token) {
      console.error('No access token found in response.');
      throw new Error('Invalid SSO response: Missing access token.');
    }

    // Store the JWT in sessionStorage
    sessionStorage.setItem('access_token', response.data.access_token);
    console.log('Access token saved to sessionStorage:', response.data.access_token);
  } catch (error) {
    console.error('Failed to authenticate via SSO key:', error);
    throw error;
  }
}
