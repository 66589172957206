// src/pages/dashboards/subaccount/SubAccountOrders.page.tsx
import React, { useEffect, useState } from 'react';
import { Button, LoadingOverlay, NumberInput, Paper, Stack, Table, Title } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { fetchBillingData, updateBillingInfo } from '@/services/subaccountService';

const SubAccountOrdersPage: React.FC = () => {
  const [billingData, setBillingData] = useState({
    currentBalance: 0,
    balanceMinimum: 10,
    balanceMaximum: 100,
  });
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [isEdited, setIsEdited] = useState(false);

  useEffect(() => {
    const fetchBilling = async () => {
      setLoading(true);
      try {
        const data = await fetchBillingData();
        setBillingData({
          currentBalance: data.account_balance,
          balanceMinimum: data.balance_minimum,
          balanceMaximum: data.balance_maximum,
        });
      } catch (error) {
        notifications.show({
          title: 'Error',
          message: 'Failed to fetch billing data.',
          color: 'red',
        });
      } finally {
        setLoading(false);
      }
    };

    fetchBilling();
  }, []);

  const handleSave = async () => {
    setSaving(true);
    try {
      await updateBillingInfo({
        balance_minimum: billingData.balanceMinimum,
        balance_maximum: billingData.balanceMaximum,
      });
      notifications.show({
        title: 'Success',
        message: 'Billing settings updated.',
        color: 'green',
      });
      setIsEdited(false);
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: 'Failed to update billing settings.',
        color: 'red',
      });
    } finally {
      setSaving(false);
    }
  };

  return (
    <Stack
      gap="lg"
      p="lg"
      align="center"
      justify="center"
      style={{ height: '100%', padding: 'var(--mantine-spacing-lg)' }}
    >
      <Title order={2}>Orders</Title>
      <LoadingOverlay visible={loading} />
      <Button
        component="a"
        href={import.meta.env.VITE_STRIPE_URL}
        target="_blank"
        rel="noopener noreferrer"
        variant="filled"
        size="lg"
      >
        Configure Stripe
      </Button>
      <Paper
        withBorder
        shadow="sm"
        radius="md"
        style={{ width: '100%', minWidth: '100%', overflow: 'auto' }}
      >
        <Table striped highlightOnHover withTableBorder withColumnBorders>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Current Balance</Table.Th>
              <Table.Th>Minimum Balance</Table.Th>
              <Table.Th>Action</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            <Table.Tr>
              <Table.Td>${billingData.currentBalance.toFixed(2)}</Table.Td>
              <Table.Td>
                <NumberInput
                  value={billingData.balanceMaximum}
                  onChange={(value) => {
                    setBillingData((prev) => ({
                      ...prev,
                      balanceMaximum: typeof value === 'number' ? value : prev.balanceMaximum,
                    }));
                    setIsEdited(true);
                  }}
                  min={billingData.balanceMinimum}
                  prefix="$"
                  allowDecimal={false}
                  allowNegative={false}
                  allowLeadingZeros={false}
                />
              </Table.Td>
              <Table.Td>
                <Button
                  onClick={handleSave}
                  loading={saving}
                  variant="filled"
                  size="sm"
                  disabled={!isEdited}
                >
                  Save
                </Button>
              </Table.Td>
            </Table.Tr>
          </Table.Tbody>
        </Table>
      </Paper>
    </Stack>
  );
};

export default SubAccountOrdersPage;
