import React, { useEffect, useState } from 'react';
import { IconCheck, IconX } from '@tabler/icons-react';
import { Button, Select, Stack, Table, TextInput, Title } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { Billing } from '@/models/Billing';
import { InstalledLocation } from '@/models/Location';
import {
  fetchAgencyLocations,
  fetchAllStripeCustomers,
  fetchBillingData,
  updateBillingInfo,
} from '@/services/agencyService';

interface StripeCustomer {
  id: string;
  name: string;
  address: string | null;
}

const AgencyBilling: React.FC = () => {
  const [locations, setLocations] = useState<InstalledLocation[]>([]);
  const [stripeCustomers, setStripeCustomers] = useState<StripeCustomer[]>([]);
  const [selectedCustomers, setSelectedCustomers] = useState<Record<string, string | null>>({});
  const [modifiedRows, setModifiedRows] = useState<Set<string>>(new Set());
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [billingData, setBillingData] = useState<Record<string, Billing>>({});
  const [modifiedBillingData, setModifiedBillingData] = useState<Set<string>>(new Set());

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [locationsData, customersData, billingDataResponse] = await Promise.all([
          fetchAgencyLocations(),
          fetchAllStripeCustomers(),
          fetchBillingData(),
        ]);

        if (Array.isArray(locationsData)) {
          setLocations(locationsData);
        } else {
          throw new Error('Invalid locations data format');
        }

        if (Array.isArray(customersData)) {
          const formattedCustomers = customersData.map((customer) => ({
            id: customer.id,
            name: customer.name,
            address: customer.address
              ? `${customer.address.line1}, ${customer.address.city}, ${customer.address.state}, ${customer.address.postal_code}`
              : 'No address',
          }));
          setStripeCustomers(formattedCustomers);
        } else {
          throw new Error('Invalid customers data format');
        }

        if (Array.isArray(billingDataResponse)) {
          // Transform the billingDataResponse into a record for easier lookup
          const initialBillingData: Record<string, Billing> = billingDataResponse.reduce(
            (acc: Record<string, Billing>, billing) => {
              acc[billing.location_id] = billing;
              return acc;
            },
            {} as Record<string, Billing>
          );
          setBillingData(initialBillingData);

          const initialSelectedCustomers = billingDataResponse.reduce(
            (acc: Record<string, string | null>, billing) => {
              acc[billing.location_id] = billing.external_billing_id;
              return acc;
            },
            {} as Record<string, string | null>
          );
          setSelectedCustomers(initialSelectedCustomers);
        } else {
          throw new Error('Invalid billing data format');
        }
      } catch (err: any) {
        setError(err.message || 'Failed to fetch data');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSelectChange = (locationId: string, stripeCustomerId: string | null) => {
    console.log('Selected customer:', stripeCustomerId);
    console.log('Location ID:', locationId);
    setSelectedCustomers((prev) => ({
      ...prev,
      [locationId]: stripeCustomerId ?? null,
    }));

    const initialValue = selectedCustomers[locationId];
    setModifiedRows((prev) => {
      const updated = new Set(prev);

      if (stripeCustomerId === initialValue) {
        updated.delete(locationId);
      } else {
        updated.add(locationId);
      }

      return updated;
    });
  };

  // Handle changes to the min and max balance fields
  const handleBillingChange = (
    locationId: string,
    field: 'balance_minimum' | 'balance_maximum',
    value: string
  ) => {
    setBillingData((prev) => {
      const updated = { ...prev };
      if (updated[locationId]) {
        updated[locationId] = {
          ...updated[locationId],
          [field]: parseFloat(value) || 0, // Store as number
        };
      }
      return updated;
    });
    setModifiedBillingData((prev) => {
      const updated = new Set(prev);
      updated.add(locationId);
      return updated;
    });
  };

  const handleSave = async (locationId: string) => {
    const stripeCustomerId = selectedCustomers[locationId] || null;
    const billing = billingData[locationId];
    console.log('Billing information:', billingData);
    console.log('Saving billing information:', locationId, stripeCustomerId, billing);
    try {
      const savePayload: any = {
        location_id: locationId,
        stripe_customer_id: stripeCustomerId,
        balance_minimum: billing?.balance_minimum ?? 0,
        balance_maximum: billing?.balance_maximum ?? 0,
      };

      await updateBillingInfo(savePayload);

      notifications.show({
        title: 'Billing notification',
        message: 'Billing information saved successfully!',
        icon: <IconCheck />,
      });

      // Update billingData **locally** to reflect changes immediately
      setBillingData((prev) => {
        const updated = { ...prev };

        // Ensure we update the correct location
        updated[locationId] = {
          ...updated[locationId],
          external_billing_id: stripeCustomerId ?? '',
          balance_minimum: savePayload.balance_minimum,
          balance_maximum: savePayload.balance_maximum,
        };

        return updated;
      });

      // Remove location from modified sets
      setModifiedBillingData((prev) => {
        const updated = new Set(prev);
        updated.delete(locationId);
        return updated;
      });

      // Remove location from modified sets
      setModifiedRows((prev) => {
        const updated = new Set(prev);
        updated.delete(locationId);
        return updated;
      });
    } catch (error) {
      console.error('Error saving billing information:', error);
      notifications.show({
        title: 'Billing notification',
        message: 'Failed to save billing information. Please try again.',
        icon: <IconX />,
        color: 'red',
      });
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (locations.length === 0) {
    return <div>No billing information available.</div>;
  }

  return (
    <Stack gap="lg" p="lg">
      <Title order={2}>Billing Information</Title>
      {Object.keys(billingData).length > 0 && (
        <Table striped highlightOnHover withTableBorder withColumnBorders>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Sub-account Name</Table.Th>
              <Table.Th>Sub-account ID</Table.Th>
              <Table.Th>Billing Information</Table.Th>
              <Table.Th>Stripe Id</Table.Th>
              <Table.Th>Current Balance</Table.Th>
              <Table.Th>Minimum Balance</Table.Th>
              <Table.Th>Maximum Balance</Table.Th>
              <Table.Th>Actions</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {locations.map((location) => {
              const assignedStripeIds = Object.values(selectedCustomers).filter(
                (id) => id !== null && id !== selectedCustomers[location.id]
              );

              const availableStripeCustomers = stripeCustomers.filter(
                (customer) => !assignedStripeIds.includes(customer.id)
              );
              // Use the billingData record for easier lookup
              const locationBilling = billingData[location.id];

              return (
                <Table.Tr key={location.id}>
                  <Table.Td>{location.name}</Table.Td>
                  <Table.Td>{location.id}</Table.Td>
                  <Table.Td>{location.address}</Table.Td>
                  <Table.Td>
                    <Select
                      placeholder="Select Stripe Customer"
                      data={availableStripeCustomers.map((customer) => ({
                        value: customer.id,
                        label: `${customer.name} (${customer.id}) - ${customer.address}`,
                      }))}
                      searchable
                      clearable
                      allowDeselect
                      withScrollArea
                      nothingFoundMessage="No customers found"
                      value={selectedCustomers[location.id] || null}
                      onChange={(value) => handleSelectChange(location.id, value)}
                    />
                  </Table.Td>
                  <Table.Td>${locationBilling?.account_balance ?? 'N/A'}</Table.Td>
                  <Table.Td>
                    <TextInput
                      type="number"
                      value={locationBilling?.balance_minimum?.toString() ?? ''}
                      onChange={(event) =>
                        handleBillingChange(
                          location.id,
                          'balance_minimum',
                          event.currentTarget.value
                        )
                      }
                    />
                  </Table.Td>
                  <Table.Td>
                    <TextInput
                      type="number"
                      value={locationBilling?.balance_maximum?.toString() ?? ''}
                      onChange={(event) =>
                        handleBillingChange(
                          location.id,
                          'balance_maximum',
                          event.currentTarget.value
                        )
                      }
                    />
                  </Table.Td>
                  <Table.Td>
                    <Button
                      id={`save-button-${location.id}`}
                      onClick={() => handleSave(location.id)}
                      disabled={
                        !modifiedRows.has(location.id) && !modifiedBillingData.has(location.id)
                      }
                    >
                      Save
                    </Button>
                  </Table.Td>
                </Table.Tr>
              );
            })}
          </Table.Tbody>
        </Table>
      )}
    </Stack>
  );
};

export default AgencyBilling;
