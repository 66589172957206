// /srv/services/subaccountService.ts

import { ApiKeyResponse, ApiResponse, SetTestModeResponse } from '@/interfaces/Api';
import { CreateSender, Sender } from '@/interfaces/Senders';
import { Billing } from '@/models/Billing';
import { api } from '@/services/apiService';

export const fetchStripeSession = async (
  email: string
): Promise<ApiResponse<{ sessionId: string }>> => {
  try {
    const response = await api.post<ApiResponse<{ sessionId: string }>>('/stripe/session', {
      email,
    });
    return response.data;
  } catch (error: any) {
    console.error('Error fetching Stripe session:', error);
    throw new Error(error.message || 'Failed to fetch Stripe session');
  }
};

export const fetchStripeSessionStatus = async (
  sessionId: string
): Promise<ApiResponse<{ status: string }>> => {
  try {
    const response = await api.get<ApiResponse<{ status: string }>>(
      `/stripe/session-status/${sessionId}`
    );
    return response.data;
  } catch (error: any) {
    console.error('Error fetching Stripe session status:', error);
    throw new Error(error.message || 'Failed to fetch Stripe session status');
  }
};

// Function to generate a new API key
export const generateApiKey = async (locationId: string): Promise<ApiResponse<ApiKeyResponse>> => {
  try {
    const response = await api.post<ApiResponse<ApiKeyResponse>>('/apikey/generate', {
      locationId,
    });
    return response.data;
  } catch (error: any) {
    console.error('Error generating API key:', error);
    throw new Error(error.message || 'Failed to generate API key');
  }
};

// Function to refresh the API key
export const refreshApiKey = async (locationId: string): Promise<ApiResponse<ApiKeyResponse>> => {
  try {
    const response = await api.post<ApiResponse<ApiKeyResponse>>('/apikey/refresh', { locationId });
    return response.data;
  } catch (error: any) {
    console.error('Error refreshing API key:', error);
    throw new Error(error.message || 'Failed to refresh API key');
  }
};

// Function to fetch the existing API key
export const fetchApiKey = async (): Promise<ApiResponse<ApiKeyResponse>> => {
  try {
    const response = await api.get<ApiResponse<ApiKeyResponse>>('/apikey/fetch');
    return response.data;
  } catch (error: any) {
    console.error('Error fetching API key:', error);
    throw new Error(error.message || 'Failed to fetch API key');
  }
};

// Function to fetch the existing API key
export const setApiKeyTestMode = async (testMode: boolean): Promise<SetTestModeResponse> => {
  try {
    const response = await api.post<SetTestModeResponse>('/apikey/set-test-mode', { testMode });
    return response.data;
  } catch (error: any) {
    console.error('Error toggling test mode for API key:', error);
    throw new Error(error.message || 'Failed to toggle test mode!');
  }
};

// Function to create a new sender (using FormData)
export const createSender = async (sender: CreateSender): Promise<ApiResponse<CreateSender>> => {
  try {
    const response = await api.post<ApiResponse<CreateSender>>('/senders/create', sender);
    return response.data;
  } catch (error: any) {
    console.error('Error creating sender:', error);
    throw new Error(error.message || 'Failed to create sender');
  }
};

// Function to update an existing sender
export const updateSender = async (sender: Partial<Sender>): Promise<ApiResponse<Sender>> => {
  try {
    const response = await api.put<ApiResponse<Sender>>(`/senders/${sender.id}`, sender);
    return response.data;
  } catch (error: any) {
    console.error('Error updating sender:', error);
    throw new Error(error.message || 'Failed to update sender');
  }
};

// Function to delete a sender by ID
export const deleteSender = async (
  locationId: string,
  senderId: number
): Promise<ApiResponse<Sender>> => {
  try {
    const response = await api.delete<ApiResponse<Sender>>(
      `/senders/${senderId}`,
      { data: { locationId } } // Axios DELETE requires `data` instead of passing body directly
    );
    return response.data;
  } catch (error: any) {
    console.error('Error deleting sender:', error);
    throw new Error(error.message || 'Failed to delete sender');
  }
};

// Function to search for senders based on criteria
export const searchSenders = async (
  locationId: string,
  filters: Partial<Sender> & { id?: number }
): Promise<ApiResponse<Sender[]>> => {
  try {
    const queryParams = new URLSearchParams({ locationId });

    Object.entries(filters).forEach(([key, value]) => {
      if (value !== undefined) {
        queryParams.append(key, String(value));
      }
    });

    const response = await api.get<ApiResponse<Sender[]>>(
      `/senders/search?${queryParams.toString()}`
    );
    return response.data;
  } catch (error: any) {
    console.error('Error searching senders:', error);
    throw new Error(error.message || 'Failed to search senders');
  }
};

/**
 * Fetches all billing data from the sub-account.
 */
export async function fetchBillingData(): Promise<Billing> {
  try {
    const response = await api.get<{ data: Billing }>('/subaccounts/billing');
    return response.data.data ?? []; // Ensure always returns an array
  } catch (error) {
    console.error('Error fetching billing data:', error);
    throw error;
  }
}

/**
 * Saves or updates billing information.
 */
interface SubAccountBillingUpdateRequest {
  balance_minimum?: number;
  balance_maximum?: number;
}

export async function updateBillingInfo(
  billingData: SubAccountBillingUpdateRequest
): Promise<ApiResponse> {
  try {
    const response = await api.post<ApiResponse>('/subaccounts/billing/save', billingData);
    return response.data;
  } catch (error) {
    console.error('Error saving billing information:', error);
    throw error;
  }
}

/**
 * Fetches all order data from the sub-account.
 * TODO: CREATE A MODEL FOR ORDERS
 */
export async function fetchOrders(): Promise<Billing> {
  try {
    const response = await api.get<{ data: Billing }>('/subaccounts/orders');
    return response.data.data ?? []; // Ensure always returns an array
  } catch (error) {
    console.error('Error fetching billing data:', error);
    throw error;
  }
}
