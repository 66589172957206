import React, { useEffect, useRef, useState } from 'react';
import { Button, Group, Select } from '@mantine/core';

interface InsertVariablesProps {
  variables: string[];
  children: React.ReactNode;
  onInsert?: (field: string, value: string) => void;
}

const InsertVariables: React.FC<InsertVariablesProps> = ({ variables, children, onInsert }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [selectedVariable, setSelectedVariable] = useState<string | null>(null);
  const activeInputRef = useRef<HTMLInputElement | HTMLTextAreaElement | null>(null);

  useEffect(() => {
    const handleFocusIn = (event: FocusEvent) => {
      const target = event.target as HTMLElement;
      if (
        (target instanceof HTMLInputElement || target instanceof HTMLTextAreaElement) &&
        containerRef.current?.contains(target)
      ) {
        activeInputRef.current = target;
      }
    };

    document.addEventListener('focusin', handleFocusIn);
    return () => {
      document.removeEventListener('focusin', handleFocusIn);
    };
  }, []);

  const insertAtCursor = () => {
    const el = activeInputRef.current;
    if (!el || !selectedVariable) {
      return;
    }

    const start = el.selectionStart ?? 0;
    const end = el.selectionEnd ?? 0;
    const value = el.value;
    const newValue = value.slice(0, start) + selectedVariable + value.slice(end);

    // Optional: Use `name` attribute to get form field
    const fieldName = el.getAttribute('name');
    if (fieldName && typeof onInsert === 'function') {
      onInsert(fieldName, newValue);
    } else {
      el.value = newValue;
      el.selectionStart = start + selectedVariable.length;
      el.selectionEnd = start + selectedVariable.length;
      el.dispatchEvent(new Event('input', { bubbles: true }));
    }
  };

  return (
    <div ref={containerRef}>
      <Group gap="sm" mb="sm" align="flex-end">
        <Select
          label="Tags"
          placeholder="Choose a tag"
          searchable
          clearable
          data={variables.map((v) => ({ label: v, value: v }))}
          value={selectedVariable}
          onChange={setSelectedVariable}
        />
        <Button disabled={!selectedVariable} onClick={insertAtCursor}>
          Insert
        </Button>
      </Group>

      {children}
    </div>
  );
};

export default InsertVariables;
