// /src/components/Products/ProductGallery.tsx
import React, { useEffect, useState } from 'react';
import { IconAlertCircle } from '@tabler/icons-react';
import { useSelector } from 'react-redux';
import { Alert, Button, Group, Loader, Modal, SimpleGrid, Stack, Text, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import ProductCard from '@/components/Products/ProductCard';
import { SearchProductsParams } from '@/interfaces/Api';
import { Product } from '@/interfaces/Product';
import { archiveProductById, searchProducts } from '@/services/agencyService';
import { selectSessionType } from '@/store/selectors/sessionSelectors';

const ProductGallery: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [deleteModalOpen, { open: openDeleteModal, close: closeDeleteModal }] =
    useDisclosure(false);

  const _sessionType = useSelector(selectSessionType);

  const loadProducts = async () => {
    setLoading(true);
    setError(null);
    try {
      const params: SearchProductsParams = {};

      const response = await searchProducts(params);
      console.log('loadProducts -> response', response);
      if (response.status === 'success' && response.data) {
        setProducts(response.data);
      } else {
        setProducts([]);
        setError(response.message || 'Failed to load products.');
        notifications.show({
          title: 'Error',
          message: response.message || 'Failed to load products.',
          color: 'red',
        });
      }
    } catch (_err) {
      setError('An unexpected error occurred while fetching products.');
      notifications.show({
        title: 'Error',
        message: 'An unexpected error occurred while fetching products.',
        color: 'red',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadProducts();
  }, []);

  const handleDelete = async () => {
    if (!selectedProduct) {
      return;
    }
    try {
      await archiveProductById(selectedProduct.id.toString());
      setProducts((prevProducts) =>
        prevProducts.filter((product) => product.id !== selectedProduct.id)
      );
      notifications.show({
        title: 'Success',
        message: `Product "${selectedProduct.name}" deleted successfully.`,
        color: 'green',
      });
    } catch (_err) {
      notifications.show({
        title: 'Error',
        message: 'Failed to delete product. Please try again.',
        color: 'red',
      });
    } finally {
      closeDeleteModal();
      setSelectedProduct(null);
    }
  };

  if (loading) {
    return <Loader size="md" variant="dots" />;
  }

  if (error) {
    return (
      <Alert icon={<IconAlertCircle size={16} />} title="Error" color="red">
        {error}
      </Alert>
    );
  }

  if (products.length === 0) {
    return <Title order={4}>No products available.</Title>;
  }

  return (
    <Stack gap="lg" p="lg" align="stretch">
      <SimpleGrid
        cols={{ base: 1, sm: 2, lg: 3 }}
        spacing={{ base: 'sm', sm: 'md', lg: 'lg' }}
        verticalSpacing={{ base: 'sm', sm: 'md', lg: 'lg' }}
        type="media"
      >
        {products.map((product) => (
          <ProductCard
            key={product.id}
            product={product}
            onEdit={(id) => console.log(`Edit product with ID: ${id}`)}
            onDelete={() => {
              setSelectedProduct(product);
              openDeleteModal();
            }}
          />
        ))}
      </SimpleGrid>

      <Modal opened={deleteModalOpen} onClose={closeDeleteModal} title="Confirm Deletion">
        <Text>Are you sure you want to delete the product "{selectedProduct?.name}"?</Text>
        <Group mt="md" justify="flex-end">
          <Button variant="outline" color="gray" onClick={closeDeleteModal}>
            Cancel
          </Button>
          <Button color="red" onClick={handleDelete}>
            Delete
          </Button>
        </Group>
      </Modal>
    </Stack>
  );
};

export default ProductGallery;
