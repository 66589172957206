// /src/utils/guards/AuthGuard.tsx
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { SSOSessionType } from '@/interfaces/SSOSession';
import { RootState } from '@/store/store'; // Assuming RootState is defined in the store

interface AuthGuardProps {
  sessionTypes: SSOSessionType[]; // Accepts an array of allowed session types
  children: React.ReactNode;
}

const AuthGuard: React.FC<AuthGuardProps> = ({ sessionTypes, children }) => {
  const { data: session, loading, error } = useSelector((state: RootState) => state.session);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  // Check if the session and sessionType are valid
  if (!session || !session.sessionType || !sessionTypes.includes(session.sessionType)) {
    return <Navigate to={import.meta.env.VITE_APP_UNAUTHORIZED} replace />;
  }

  return <>{children}</>;
};

export default AuthGuard;
