import React from 'react';
import { useSelector } from 'react-redux';
import { Card, LoadingOverlay, Stack, Title } from '@mantine/core';
import SendersTable from '@/components/SendersTable/SendersTable';
import SubAccountApiKey from '@/components/SubAccountApiKey/SubAccountApiKey';
import { SSOSession } from '@/interfaces/SSOSession';
import { selectSessionData } from '@/store/selectors/sessionSelectors';

const SubaccountSettingsPage: React.FC = () => {
  const sessionData = useSelector(selectSessionData) as SSOSession;
  const locationId = sessionData.locationId;
  console.log('SESSON DATA : ', sessionData);
  console.log('LOCATION ID ', locationId);

  return (
    <Stack gap="lg" p="lg" style={{ position: 'relative' }}>
      <LoadingOverlay visible={!locationId} />
      <Title order={2}>Subaccount Settings</Title>

      {/* API Key Section (abstracted) */}
      <Card withBorder shadow="sm" p="md" radius="md" style={{ width: '100%' }}>
        <SubAccountApiKey locationId={locationId} />
      </Card>

      {/* Senders Table (abstracted) */}
      <SendersTable locationId={locationId} />
    </Stack>
  );
};

export default SubaccountSettingsPage;
