import axios from 'axios';

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;

// Utility function to get the access token from sessionStorage
function getAccessToken(): string | null {
  return sessionStorage.getItem('access_token');
}

export function deleteAccessToken(): undefined {
  sessionStorage.removeItem('access_token');
  const accessToken = getAccessToken();
  if (accessToken === undefined) {
    throw new Error('Failed to delete token.');
  }
}

// Create Axios instance (without default `Authorization` header)
export const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Attach request interceptor to dynamically set Authorization header
api.interceptors.request.use(
  (config) => {
    const token = getAccessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Attach response interceptor to handle authentication errors globally
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response?.status === 401 &&
      error.response.data?.message === 'Unauthorized: JWT token has expired.'
    ) {
      console.warn('JWT expired. Redirecting to /unauthorized...');
      window.location.href = '/unauthorized';
    }
    return Promise.reject(error);
  }
);

// Axios instance for non-authenticated requests (SSO key validation, public endpoints)
export const publicApi = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// export default async function apiRequest<TResponse>(
//   endpoint: string,
//   method: 'GET' | 'POST' | 'PUT' | 'DELETE' = 'GET',
//   body?: FormData | Record<string, any> | string,
//   headers: Record<string, string> = {},
//   retries = 3
// ): Promise<TResponse> {
//   const token = getAccessToken();
//   const isFormData = body instanceof FormData;

//   const defaultHeaders: Record<string, string> = {
//     Accept: 'application/json',
//     ...(isFormData ? {} : { 'Content-Type': 'application/json' }),
//     ...(token && { Authorization: `Bearer ${token}` }),
//     ...headers,
//   };

//   const requestOptions: RequestInit = {
//     method,
//     headers: defaultHeaders,
//   };

//   if (body !== undefined && body !== null) {
//     requestOptions.body = isFormData ? body : typeof body === 'string' ? body : JSON.stringify(body);
//   }

//   for (let attempt = 0; attempt <= retries; attempt++) {
//     try {
//       const res = await fetch(`${API_BASE_URL}${endpoint}`, requestOptions);
//       const responseText = await res.clone().text(); // Clone response for error logging

//       if (!res.ok) {
//         try {
//           const errorJson = await res.json(); // Parse JSON if possible
//           throw new Error(errorJson.detail || 'API request failed');
//         } catch {
//           throw new Error(responseText); // Fallback to text error
//         }
//       }

//       return await res.json(); // Return parsed JSON response
//     } catch (error) {
//       // console.error('API Error:', error, { endpoint, method, body, headers });
//       if (attempt === retries || method !== 'GET') {
//         throw error;
//       }
//       await new Promise((resolve) => setTimeout(resolve, 2 ** attempt * 100)); // Exponential backoff
//     }
//   }

//   throw new Error('API request failed after retries');
// }
