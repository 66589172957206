import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Center, Loader } from '@mantine/core';
import { requestSSOKey, validateSSOKey } from '@/services/ssoService';
import {
  selectSessionData,
  selectSessionError,
  selectSessionLoading,
} from '@/store/selectors/sessionSelectors';
import { fetchSession } from '@/store/slices/sessionSlice';
import { AppDispatch } from '@/store/store';

const SSOPage: React.FC = () => {
  const navigate = useNavigate();

  // setup Redux
  const dispatch = useDispatch<AppDispatch>();
  const session = useSelector(selectSessionData);
  const loading = useSelector(selectSessionLoading);
  const _error = useSelector(selectSessionError);

  useEffect(() => {
    const initializeSSO = async () => {
      try {
        console.log('Requesting new SSO key...');
        const ssoKey = await requestSSOKey();

        console.log('Validating SSO key...');
        await validateSSOKey(ssoKey);

        console.log('Fetching session...');
        dispatch(fetchSession());
      } catch (error) {
        console.error('SSO initialization failed:', error);
        navigate('/unauthorized', { replace: true });
      }
    };

    initializeSSO();
  }, [navigate, dispatch]);

  useEffect(() => {
    if (!loading && session.sessionType) {
      switch (session.sessionType) {
        case 'agency':
          navigate('/agency-dashboard', { replace: true });
          break;
        case 'spoofed_account':
        case 'account':
          navigate('/sub-account-dashboard', { replace: true });
          break;
        default:
          console.log('SSOPage :: session.sessionType : ', session.sessionType);
          navigate('/unauthorized', { replace: true });
      }
    }
  }, [loading, session, navigate]);

  return (
    <Center style={{ height: '100vh' }}>
      <Loader color="blue" size="xl" type="dots" />
    </Center>
  );
};

export default SSOPage;
