// src/pages/dashboards/agency/AgencyHome.page.tsx
import React from 'react';
import { BarChart } from '@mantine/charts';
import { Card, Group, Stack, Title } from '@mantine/core';

// Example chart data for Cards Sold
const cardsSoldData = [
  { month: 'January', value: 150 },
  { month: 'February', value: 200 },
  { month: 'March', value: 250 },
  { month: 'April', value: 300 },
  { month: 'May', value: 350 },
  { month: 'June', value: 400 },
];

// Example chart data for Revenue
const revenueData = [
  { month: 'January', value: 5000 },
  { month: 'February', value: 8000 },
  { month: 'March', value: 9000 },
  { month: 'April', value: 11000 },
  { month: 'May', value: 12000 },
  { month: 'June', value: 14000 },
];

const AgencyHome: React.FC = () => {
  return (
    <Stack gap="lg" mt="md" p="lg">
      {/* Page Title */}
      <Group justify="space-between" align="center">
        <Title order={2}>Agency Dashboard Overview</Title>
      </Group>

      {/* Cards Sold Chart */}
      <Card shadow="sm" padding="lg" withBorder>
        <Group justify="space-between" align="center" mb="sm">
          <Title order={4}>Cards Sold (Monthly)</Title>
        </Group>
        <BarChart
          data={cardsSoldData}
          dataKey="month"
          series={[{ name: 'Cards Sold', color: 'blue' }]}
          yAxisLabel="Cards Sold"
          xAxisLabel="Month"
          withLegend
          withTooltip
          maxBarWidth={40}
        />
      </Card>

      {/* Revenue Chart */}
      <Card shadow="sm" padding="lg" withBorder>
        <Group justify="space-between" align="center" mb="sm">
          <Title order={4}>Monthly Revenue</Title>
        </Group>
        <BarChart
          data={revenueData}
          dataKey="month"
          series={[{ name: 'Revenue', color: 'green' }]}
          yAxisLabel="Revenue ($)"
          xAxisLabel="Month"
          withLegend
          withTooltip
          maxBarWidth={40}
        />
      </Card>
    </Stack>
  );
};

export default AgencyHome;
