// src/pages/dashboard/agency/AgencyProductEdit.page.tsx
import React, { useEffect, useRef, useState } from 'react';
import { IconCloudUpload, IconDownload, IconX } from '@tabler/icons-react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Card,
  Group,
  Image,
  Modal,
  SegmentedControl,
  Select,
  Stack,
  Text,
  Textarea,
  TextInput,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import { Dropzone, MIME_TYPES } from '@mantine/dropzone';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import SelectHandwriting from '@/components/HandwritingSelect/SelectHandwriting';
import InsertVariables from '@/components/InsertVariables/InsertVariables';
import ProductTagManager from '@/components/Products/ProductTagManager';
import { PRODUCT_SIZE_VALUES, PRODUCT_SIZES, ProductSize } from '@/constants/productConstants';
import { Tag, TagDelta } from '@/interfaces/Product';
import { SSOSessionType } from '@/interfaces/SSOSession';
import { getProductById, submitOrUpdateProduct } from '@/services/agencyService';
import { selectSessionType } from '@/store/selectors/sessionSelectors';

const productSizes = PRODUCT_SIZES; // Reuse the shared constant

const requiredDimensions = {
  '4x6': { width: 1875, height: 1275 },
  '6x9': { width: 1875, height: 1275 },
  '6x11': { width: 3337, height: 1777 },
};

const variableTags = [
  '{{ contact.first_name }}',
  '{{ contact.last_name }}',
  '{{ contact.name }}',
  '{{ sender.name }}',
  '{{ sender.first_name }}',
  '{{ sender.last_name }}',
  '{{ sender.business_name }}',
];

interface ProductFormValues {
  id?: number; // Optional ID for editing
  name: string;
  description: string;
  greeting: string;
  message: string;
  salutation: string;
  handwritingStyle: string;
  size: ProductSize;
  status: string;
  frontImage: File | null; // File for new uploads
  frontImageUrl: string | null; // URL for displaying existing image
  tags: Tag[];
}

const ProductEditPage: React.FC = () => {
  const theme = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>(); // Capture id from route params
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalImage, setModalImage] = useState<string | null>(null);
  const openRef = useRef<() => void>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [tagDelta, setTagDelta] = useState<TagDelta>({
    addedTags: [],
    removedTagIds: [],
    remainingTagIds: [],
  });

  const sessionType = useSelector(selectSessionType);

  const form = useForm<ProductFormValues>({
    initialValues: {
      id: undefined,
      name: '',
      description: '',
      greeting: '',
      message: '',
      salutation: '',
      handwritingStyle: '4',
      size: '6x9',
      status: 'draft',
      frontImage: null,
      frontImageUrl: null,
      tags: [],
    },
    validate: {
      name: (value) => (value.trim().length > 0 ? null : 'Name is required'),
      description: (value) => (value.trim().length > 0 ? null : 'Description is required'),
      message: (value) => (value.trim().length > 0 ? null : 'Message is required'),
      handwritingStyle: (value) =>
        value.trim().length > 0 ? null : 'Handwriting style is required',
      size: (value) => (value ? null : 'Size is required'),
      status: (value) => (value ? null : 'Status is required'),
      frontImage: (value, values) =>
        value || values.frontImageUrl ? null : 'Front image is required',
    },
  });

  const navigateToProducts = (replace: boolean = false) => {
    if (sessionType === SSOSessionType.AGENCY) {
      navigate('/agency-dashboard/products', { replace });
    } else if (
      sessionType === SSOSessionType.ACCOUNT ||
      sessionType === SSOSessionType.SPOOFED_ACCOUNT
    ) {
      navigate('/sub-account-dashboard/products', { replace });
    }
  };

  useEffect(() => {
    if (id) {
      setIsEditing(!!id);
      const loadProduct = async () => {
        try {
          const product = await getProductById(id);
          console.log('PRODUCT : ', product);
          if (!product) {
            notifications.show({
              title: 'Product Not Found',
              message: 'The product you are trying to edit does not exist.',
              color: 'yellow',
            });
            navigateToProducts(true);
            return;
          }

          const validSize = PRODUCT_SIZE_VALUES.includes(product.attributes.size as ProductSize)
            ? (product.attributes.size as ProductSize)
            : '6x9';

          form.setValues({
            id: Number(product.id),
            name: product.name,
            description: product.description,
            greeting: product.attributes.greeting,
            message: product.attributes.message,
            salutation: product.attributes.salutation,
            handwritingStyle: product.attributes.handwritingStyle,
            size: validSize,
            status: product.status,
            frontImage: null,
            frontImageUrl: product.attributes.frontImageUrl,
            tags: product.tags || [],
          });
        } catch (error) {
          notifications.show({
            title: 'Error',
            message: 'Failed to load product details. Please try again later.',
            color: 'red',
          });
        }
      };

      loadProduct();
    }
  }, [id, navigate]);

  // The image can have a tolerance of 5% smaller than the required dimensions
  const validateImageDimensions = (file: File, callback: (isValid: boolean) => void) => {
    console.log('Validate Image Dimensions');
    const img = document.createElement('img');

    img.onload = () => {
      const { width, height } = img;
      const { width: reqWidth, height: reqHeight } = requiredDimensions[form.values.size];

      // Calculate 5% tolerance only for undersized images
      const minWidth = reqWidth * 0.94; // 95% of required width
      const minHeight = reqHeight * 0.94; // 95% of required height

      // Validate: Image can be smaller by up to 5%, but not larger
      const isValid =
        width >= minWidth && width <= reqWidth && height >= minHeight && height <= reqHeight;

      callback(isValid);
    };

    img.src = URL.createObjectURL(file);
  };

  const handleImageUpload = (files: File[]) => {
    const file = files[0];
    if (file) {
      validateImageDimensions(file, (isValid) => {
        if (isValid) {
          form.setFieldValue('frontImage', file); // Store the File object directly
        } else {
          notifications.show({
            title: 'Invalid Image Dimensions',
            message: `Please upload an image with dimensions ${requiredDimensions[form.values.size].width}x${requiredDimensions[form.values.size].height}px (±10px tolerance).`,
            color: 'red',
          });
        }
      });
    }
  };

  const handleSubmit = async (values: ProductFormValues) => {
    console.log('Handle Submit : ', values);
    if (!values.frontImage && !values.frontImageUrl) {
      notifications.show({
        title: 'Validation Error',
        message: 'Please upload a product image',
        color: 'red',
      });
      return;
    }

    try {
      setIsSubmitting(true);

      const formData = new FormData();
      formData.append('name', values.name.trim());
      formData.append('description', values.description.trim());
      formData.append('greeting', values.greeting.trim());
      formData.append('message', values.message.trim());
      formData.append('salutation', values.salutation.trim());
      formData.append('handwritingStyle', String(values.handwritingStyle));
      formData.append('size', values.size);
      formData.append('status', values.status);
      formData.append('tagsDelta', JSON.stringify(tagDelta));
      if (values.frontImage) {
        formData.append('frontImage', values.frontImage);
      }

      if (isEditing) {
        formData.append('id', String(values.id)); // Include ID for edit
      }

      await submitOrUpdateProduct(formData);

      notifications.show({
        title: 'Success',
        message: isEditing ? 'Product updated successfully' : 'Product created successfully',
        color: 'green',
      });

      navigateToProducts(false);
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: error instanceof Error ? error.message : 'Failed to submit product',
        color: 'red',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handwritingStyles = [
    '101_Optimus_Prime',
    '102_Binary_Bard',
    '103_Quantum_Quip',
    '104_Analytic_Atom',
    '106_Cerebral_Circuit',
    '107_Reflective_Relay_2',
    '109_Thoughtful_Transistor',
    '10_Birthday_Card',
    '110_Bright_Byte',
    '112_Pensive_Pixel',
    '113_Judicious_Jolt',
    '114_Astute_Actuator',
    '115_Insightful_Interface',
    '118_Prudent_Processor',
    '119_Wise_Wire',
    '11_Scully_Not_Molder',
    '120_Neura_Nomad',
    '121_Rosario_Type',
    '12_Wow_Signal',
    '1_Hi_Clarice',
    '20_Notey',
    '2_Practiced_Sharpie',
    '3_Bold_Note',
    '4_Foxy_Lady',
    '5_That_Dude',
    '6_Normal_Print',
    '7_Shopping_List',
    '8_Lazy_Note',
    '9_Blurred_Lines',
  ].map((entry) => {
    const [id, ...nameParts] = entry.split('_');
    const label = nameParts.join(' ').replace(/_/g, ' ');
    const name = label.replace(/\b\w/g, (c) => c.toUpperCase()); // Capitalize
    const imageUrl = `https://directmail.sfo3.cdn.digitaloceanspaces.com/handwriting_samples/${entry}.png`;
    return {
      id,
      name,
      imageUrl,
    };
  });

  return (
    <Stack gap="lg" p="lg">
      <Text size="xl" fw={700}>
        {id ? 'Edit Product' : 'Create Postcard'}
      </Text>

      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Stack gap="md">
          <Group gap="md" grow>
            <TextInput
              label="Name"
              placeholder="Enter product name"
              {...form.getInputProps('name')}
              required
            />

            <SegmentedControl
              name="Status"
              value={form.values.status}
              onChange={(value) => form.setFieldValue('status', value)}
              data={[
                { label: 'Draft', value: 'draft' },
                { label: 'Published', value: 'published' },
              ]}
              styles={(theme) => ({
                indicator: {
                  // This specifically targets the sliding part
                  backgroundColor:
                    form.values.status === 'draft' ? theme.colors.orange[6] : theme.colors.green[6],
                },
                label: {
                  // This ensures the text is white when the label is active
                  '&[dataActive]': {
                    color: theme.white,
                  },
                },
              })}
            />
          </Group>

          <Textarea
            label="Description"
            placeholder="Enter product description"
            {...form.getInputProps('description')}
            required
          />

          <Card shadow="sm" padding="lg" radius="md" withBorder>
            <InsertVariables
              variables={variableTags}
              onInsert={(field, value) => form.setFieldValue(field, value)}
            >
              <Stack gap="md">
                {/* <TextInput
                                    name="greeting"
                                    label="Greeting"
                                    placeholder="Enter product greeting"
                                    {...form.getInputProps('greeting')}
                                /> */}

                <Textarea
                  name="message"
                  label="Message"
                  placeholder="Hi {{ contact.first_name }},

We're sending a note
to let say that YOU
are a valued and 
appreciated customer!

Regards,
{{ sender.name }}"
                  {...form.getInputProps('message')}
                  autosize
                  minRows={8}
                  maxRows={20}
                  required
                />

                {/* <Textarea
                                    name="salutation"
                                    label="Salutation"
                                    placeholder="Enter product salutation"
                                    {...form.getInputProps('salutation')}
                                    autosize
                                    minRows={2}
                                    maxRows={2}
                                /> */}
              </Stack>
            </InsertVariables>
          </Card>

          <Group gap="md" grow>
            <SelectHandwriting
              data={handwritingStyles}
              value={form.values.handwritingStyle}
              onChange={(selectedId) =>
                form.setFieldValue(
                  'handwritingStyle',
                  selectedId ? selectedId : form.values.handwritingStyle
                )
              }
              label="Handwriting Style"
              placeholder="Choose handwriting"
              required
            />
            {/* <NumberInput
                            label="Retail Price (USD)"
                            placeholder="Enter product price"
                            {...form.getInputProps('price')}
                            min={0}
                            decimalScale={2}
                            required
                            prefix="$"
                        /> */}

            {/* <NumberInput
                            label="Cost (USD)"
                            placeholder="Enter product cost"
                            {...form.getInputProps('cost')}
                            min={0}
                            decimalScale={2}
                            required
                            prefix="$"
                        /> */}

            <Select label="Size" data={productSizes} {...form.getInputProps('size')} required />
          </Group>

          <ProductTagManager
            initialTags={form.values.tags}
            requiredTags={[form.values.size]}
            onTagsChange={setTagDelta}
          />

          <Dropzone
            openRef={openRef}
            onDrop={handleImageUpload}
            radius="md"
            accept={[MIME_TYPES.png, MIME_TYPES.jpeg]}
            maxSize={5 * 1024 ** 2}
            styles={{
              root: {
                border: `1px solid ${colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[4]}`,
                backgroundColor: colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
                padding: theme.spacing.sm,
                borderRadius: theme.radius.sm,
                '&:hover': {
                  borderColor: theme.colors.blue[5],
                },
                '&:focusWithin': {
                  borderColor: theme.colors.blue[6],
                },
              },
            }}
          >
            <div style={{ pointerEvents: 'none' }}>
              <Group justify="center">
                <Dropzone.Accept>
                  <IconDownload size={50} color={theme.colors.blue[6]} stroke={1.5} />
                </Dropzone.Accept>
                <Dropzone.Reject>
                  <IconX size={50} color={theme.colors.red[6]} stroke={1.5} />
                </Dropzone.Reject>
                <Dropzone.Idle>
                  <IconCloudUpload size={50} stroke={1.5} />
                </Dropzone.Idle>
              </Group>

              <Text ta="center" fw={700} fz="lg" mt="xl">
                <Dropzone.Accept>Drop front image files here</Dropzone.Accept>
                <Dropzone.Reject>Only images less than 5MB</Dropzone.Reject>
                <Dropzone.Idle>Upload front image</Dropzone.Idle>
              </Text>
              <Text ta="center" fz="sm" mt="xs" c="dimmed">
                Drag'n'drop image files or click here to upload. We can accept only <i>.png</i>,{' '}
                <i>.jpeg</i>, or <i>.jpg</i> files that are less than 5MB in size.
              </Text>
            </div>
          </Dropzone>

          <Image
            src={
              form.values.frontImage
                ? URL.createObjectURL(form.values.frontImage) // Preview newly uploaded image
                : isEditing && form.values.frontImageUrl // Show existing image if editing
                  ? form.values.frontImageUrl
                  : `https://placehold.co/${form.values.size === '6x11' ? '3337x1777' : '1875x1275'}`
            }
            alt="Front Image Preview"
            w="100%"
            maw={300}
            h="auto"
            mt="md"
            mx="auto"
            onClick={() =>
              form.values.frontImage
                ? setModalImage(URL.createObjectURL(form.values.frontImage))
                : isEditing && form.values.frontImageUrl
                  ? setModalImage(form.values.frontImageUrl)
                  : null
            }
            style={{
              cursor:
                form.values.frontImage || (isEditing && form.values.frontImageUrl)
                  ? 'pointer'
                  : 'default',
              objectFit: 'contain',
            }}
          />

          <Group justify="flex-end" gap="sm">
            <Button
              variant="light"
              onClick={() => navigateToProducts(false)}
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button type="submit" loading={isSubmitting}>
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </Button>
          </Group>
        </Stack>
      </form>

      <Modal
        opened={!!modalImage}
        onClose={() => setModalImage(null)}
        size="lg"
        title="Image Preview"
      >
        {modalImage && <Image src={modalImage} alt="Modal Preview" width="100%" />}
      </Modal>
    </Stack>
  );
};

export default ProductEditPage;
