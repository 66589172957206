// src/components/ColorSchemeToggle/ColorSchemeToggle.tsx
import { IconMoonStars, IconSun } from '@tabler/icons-react';
import { ActionIcon, useMantineColorScheme } from '@mantine/core';

const ColorSchemeToggle = () => {
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();

  return (
    <ActionIcon
      variant="default"
      onClick={() => toggleColorScheme()}
      size={30}
      title="Toggle color scheme"
    >
      {colorScheme === 'dark' ? <IconSun size={16} /> : <IconMoonStars size={16} />}
    </ActionIcon>
  );
};

export default ColorSchemeToggle;
