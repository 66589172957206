// src/pages/Unauthorized.page.tsx
import { useNavigate } from 'react-router-dom';
import { Button, Container, Group, Stack, Text, Title } from '@mantine/core';

const UnauthorizedPage = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    const redirectPath = import.meta.env.VITE_APP_SSO || '/sso'; // Default fallback
    navigate(redirectPath, { replace: true });
  };

  return (
    <Container size="sm" style={{ textAlign: 'center', marginTop: '5rem' }}>
      <Stack gap="lg" align="center">
        <Title order={1}>Unauthorized</Title>
        <Text size="lg" c="dimmed">
          You do not have permission to access this page.
        </Text>
        <Group justify="center">
          <Button variant="filled" color="blue" size="md" onClick={handleGoBack}>
            Login
          </Button>
        </Group>
      </Stack>
    </Container>
  );
};

export default UnauthorizedPage;
