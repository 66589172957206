// /src/pages/dashboard/agency/AgencySettings.page.tsx
import React, { useState } from 'react';
import { IconCheck, IconHelpCircle, IconX } from '@tabler/icons-react';
import {
  Accordion,
  Button,
  Card,
  Group,
  HoverCard,
  Modal,
  Stack,
  Switch,
  Text,
  TextInput,
  Title,
  Tooltip,
} from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { configureAgency } from '@/services/agencyService';

const AgencySettingsPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [apiKey, setApiKey] = useState('');
  const [isFeatureEnabled, setIsFeatureEnabled] = useState(false);
  const [modalOpen, { open: openModal, close: closeModal }] = useDisclosure(false);
  const isMobile = useMediaQuery('(max-width: 768px)'); // Detects mobile screens

  const handleConfigure = async () => {
    setLoading(true);
    closeModal(); // Close modal before proceeding

    try {
      const response = await configureAgency();
      notifications.show({
        title: 'Success',
        message: response.message,
        color: 'green',
        icon: <IconCheck size={18} />,
      });
    } catch (error: any) {
      const errorMessage = error.response?.data?.detail || 'An unexpected error occurred';
      notifications.show({
        title: 'Error',
        message: errorMessage,
        color: 'red',
        icon: <IconX size={18} />,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack gap="lg" p="lg">
      <Title order={2}>Agency Settings</Title>

      {/* API Key Setting */}
      <Card shadow="sm" padding="lg" radius="md" withBorder>
        <Group justify="space-between">
          <Title order={3}>API Key</Title>
          {!isMobile ? (
            <HoverCard width={300} shadow="md">
              <HoverCard.Target>
                <IconHelpCircle size={18} style={{ cursor: 'pointer' }} />
              </HoverCard.Target>
              <HoverCard.Dropdown>
                <Text size="sm">
                  This key is used to authenticate API requests for your agency. Keep it secure and
                  do not share.
                </Text>
              </HoverCard.Dropdown>
            </HoverCard>
          ) : (
            <Text size="sm" color="dimmed">
              This key is used to authenticate API requests for your agency. Keep it secure and do
              not share.
            </Text>
          )}
        </Group>
        <TextInput
          mt="md"
          placeholder="Enter API Key"
          value={apiKey}
          onChange={(event) => setApiKey(event.currentTarget.value)}
        />
      </Card>

      {/* Feature Toggle Setting */}
      <Card shadow="sm" padding="lg" radius="md" withBorder>
        <Group justify="space-between">
          <Title order={3}>Enable Feature X</Title>
          {!isMobile ? (
            <Tooltip label="Toggles Feature X for all sub-accounts" withArrow>
              <IconHelpCircle size={18} />
            </Tooltip>
          ) : (
            <Text size="sm" color="dimmed">
              Toggles Feature X for all sub-accounts.
            </Text>
          )}
        </Group>
        <Switch
          mt="md"
          label="Enable Feature X"
          checked={isFeatureEnabled}
          onChange={(event) => setIsFeatureEnabled(event.currentTarget.checked)}
        />
      </Card>

      {/* Advanced Settings */}
      <Accordion mt="md" variant="contained">
        <Accordion.Item value="advanced">
          <Accordion.Control>Advanced Settings</Accordion.Control>
          <Accordion.Panel p="md">
            {' '}
            {/* ✅ Added padding directly to the panel */}
            <Text mb="sm" c="dimmed">
              These are settings meant for experienced users. Modify them only if necessary.
            </Text>
            {/* Responsive HoverCard / Inline Text for Configure Button */}
            {!isMobile ? (
              <HoverCard width={220} shadow="md">
                <HoverCard.Target>
                  <Button onClick={openModal} loading={loading} color="red">
                    Configure Agency
                  </Button>
                </HoverCard.Target>
                <HoverCard.Dropdown>
                  <Text size="sm">This will reset all sub-account API keys.</Text>
                </HoverCard.Dropdown>
              </HoverCard>
            ) : (
              <>
                <Text size="sm" color="dimmed">
                  This will reset all sub-account API keys.
                </Text>
                <Button onClick={openModal} loading={loading} color="red">
                  Configure Agency
                </Button>
              </>
            )}
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>

      {/* Confirmation Modal */}
      <Modal opened={modalOpen} onClose={closeModal} title="Confirm Configuration">
        <Text>
          This will assign new API keys to all sub-accounts. Are you sure you want to do this?
        </Text>
        <Group mt="md" justify="flex-end">
          <Button variant="outline" color="gray" onClick={closeModal}>
            Cancel
          </Button>
          <Button color="red" onClick={handleConfigure}>
            OK
          </Button>
        </Group>
      </Modal>
    </Stack>
  );
};

export default AgencySettingsPage;
