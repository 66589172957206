// /src/components/SenderForm.tsx
import React from 'react';
import { Button, Group, Modal, Stack, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { Sender } from '@/interfaces/Senders';
import { createSender } from '@/services/subaccountService';

interface SenderFormProps {
  opened: boolean;
  onClose: () => void;
  onSuccess: () => void;
  onError: (error: Error) => void;
}

const SenderForm: React.FC<SenderFormProps> = ({ opened, onClose, onSuccess, onError }) => {
  const form = useForm({
    initialValues: {
      first_name: '',
      last_name: '',
      business_name: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      postal_code: '',
    },

    validate: {
      first_name: (value) =>
        value.length >= 2 ? null : 'First name must be at least 2 characters',
      last_name: (value) => (value.length >= 2 ? null : 'Last name must be at least 2 characters'),
      business_name: (value) => (value.length >= 2 ? null : 'Business name is required'),
      address1: (value) => (value ? null : 'Address is required'),
      city: (value) => (value ? null : 'City is required'),
      state: (value) =>
        /^[A-Za-z]{2}$/.test(value) ? null : 'State must be a 2-letter code (e.g., OK)',
      postal_code: (value) => (/^\d{5}$/.test(value) ? null : 'Zip Code must be 5 digits'),
    },
  });

  const handleSubmit = async (values: Partial<Sender>) => {
    try {
      await createSender(values as Sender);
      onSuccess(); // Refresh sender list in parent
      onClose(); // Close modal
    } catch (error: any) {
      console.error('Error creating sender:', error.message);
      onError(error);
    }
  };

  return (
    <Modal opened={opened} onClose={onClose} title="Create Sender" centered>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Stack>
          <TextInput label="First Name" {...form.getInputProps('first_name')} required />
          <TextInput label="Last Name" {...form.getInputProps('last_name')} required />
          <TextInput label="Business Name" {...form.getInputProps('business_name')} required />
          <TextInput label="Address 1" {...form.getInputProps('address1')} required />
          <TextInput label="Address 2" {...form.getInputProps('address2')} />
          <TextInput label="City" {...form.getInputProps('city')} required />
          <TextInput label="State" {...form.getInputProps('state')} required maxLength={2} />
          <TextInput
            label="Zip Code"
            {...form.getInputProps('postal_code')}
            required
            maxLength={5}
          />

          <Group justify="flex-end">
            <Button variant="default" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit">Save Sender</Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  );
};

export default SenderForm;
