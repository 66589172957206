import React, { useEffect, useState } from 'react';
import { Pill, PillsInput, Stack } from '@mantine/core';
import { Tag, TagDelta } from '@/interfaces/Product';

interface ProductTagManagerProps {
  initialTags: Tag[]; // Existing tags to load initially
  requiredTags: string[]; // Required tags like size, type, etc.
  onTagsChange: (tagDelta: TagDelta) => void; // Callback to notify parent of changes
}

const ProductTagManager: React.FC<ProductTagManagerProps> = ({
  initialTags,
  requiredTags,
  onTagsChange,
}) => {
  const [currentTags, setCurrentTags] = useState<Tag[]>([]);
  const [newTags, setNewTags] = useState<string[]>([]);
  const [deletedTagIds, setDeletedTagIds] = useState<number[]>([]);

  useEffect(() => {
    // Ensure currentTags updates if initialTags change dynamically
    setCurrentTags(initialTags.filter((tag) => !requiredTags.includes(tag.name)));
  }, [initialTags]);

  useEffect(() => {
    const remainingTagIds = currentTags
      .map((tag) => tag.id!)
      .filter((id) => !deletedTagIds.includes(id));
    const addedTags = newTags;
    onTagsChange({ addedTags, removedTagIds: deletedTagIds, remainingTagIds });
  }, [currentTags, newTags, deletedTagIds, onTagsChange]);

  const handleAddTag = (tagName: string) => {
    // Prevent adding empty tags, duplicate tags, or required tags
    if (
      !tagName.trim() ||
      currentTags.some((tag) => tag.name === tagName) ||
      newTags.includes(tagName) ||
      requiredTags.includes(tagName)
    ) {
      return;
    }
    setNewTags([...newTags, tagName]);
  };

  const handleRemoveTag = (tagName: string) => {
    const existingTag = currentTags.find((tag) => tag.name === tagName);
    if (existingTag) {
      setDeletedTagIds([...deletedTagIds, existingTag.id!]);
      setCurrentTags(currentTags.filter((tag) => tag.id !== existingTag.id));
    } else {
      setNewTags(newTags.filter((tag) => tag !== tagName));
    }
  };

  return (
    <Stack gap="sm">
      <PillsInput
        label="Tags"
        description="Type and press enter to add a tag. Click the 'x' to remove."
      >
        <Pill.Group>
          {requiredTags.map((tag, index) => (
            <Pill key={index} radius="md" withRemoveButton={false}>
              {tag}
            </Pill>
          ))}

          {/* Existing Tags */}
          {currentTags.map((tag) => (
            <Pill
              key={tag.id}
              radius="md"
              withRemoveButton
              onRemove={() => handleRemoveTag(tag.name)}
            >
              {tag.name}
            </Pill>
          ))}

          {/* New Tags */}
          {newTags.map((tag, index) => (
            <Pill
              key={`new-${index}`}
              radius="md"
              withRemoveButton
              onRemove={() => handleRemoveTag(tag)}
            >
              {tag}
            </Pill>
          ))}

          <PillsInput.Field
            placeholder="Enter tag and press Enter"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                const newTag = e.currentTarget.value.trim();
                handleAddTag(newTag);
                e.currentTarget.value = '';
              } else if ((e.key === 'Backspace' || e.key === 'Delete') && !e.currentTarget.value) {
                // Remove last tag if backspace is pressed and input is empty
                if (newTags.length > 0) {
                  // Remove last new tag
                  handleRemoveTag(newTags[newTags.length - 1]);
                } else if (currentTags.length > 0) {
                  // Remove last current tag (if not required)
                  const lastTag = currentTags[currentTags.length - 1];
                  if (!requiredTags.includes(lastTag.name)) {
                    handleRemoveTag(lastTag.name);
                  }
                }
              }
            }}
          />
        </Pill.Group>
      </PillsInput>
    </Stack>
  );
};

export default ProductTagManager;
