import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ColorSchemeScript, localStorageColorSchemeManager, MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { AuthProvider } from '@/contexts/AuthContext';
import Router from '@/Router';
import store from '@/store/store';
import { theme } from '@/theme'; // Import theme from theme.ts

// Import Mantine global styles
import '@mantine/core/styles.css';
import '@mantine/charts/styles.css';
import '@mantine/notifications/styles.css';

export default function App() {
  return (
    <>
      {/* Inject a script to load system default color scheme */}
      <ColorSchemeScript defaultColorScheme="auto" />

      {/* MantineProvider with theme and color scheme manager */}
      <MantineProvider
        theme={theme}
        colorSchemeManager={localStorageColorSchemeManager()}
        defaultColorScheme="auto" // Load system default color scheme
        cssVariablesSelector=":root"
        withCssVariables
        deduplicateCssVariables
      >
        <BrowserRouter>
          <AuthProvider>
            <ReduxProvider store={store}>
              <Notifications />
              <Router />
            </ReduxProvider>
          </AuthProvider>
        </BrowserRouter>
      </MantineProvider>
    </>
  );
}
