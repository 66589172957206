import React, { useEffect, useState } from 'react';
import { IconCheck, IconEdit, IconPlus, IconTrash, IconX } from '@tabler/icons-react';
import {
  ActionIcon,
  Button,
  Group,
  Modal,
  Paper,
  Radio,
  Table,
  Text,
  TextInput,
  Tooltip,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import SenderForm from '@/components/SenderForm/SenderForm';
import { Sender } from '@/interfaces/Senders';
import { deleteSender, searchSenders, updateSender } from '@/services/subaccountService';

interface SendersTableProps {
  locationId: string | null;
}

const SendersTable: React.FC<SendersTableProps> = ({ locationId }) => {
  const [senders, setSenders] = useState<Sender[]>([]);
  const [editingSender, setEditingSender] = useState<number | null>(null);
  const [editedData, setEditedData] = useState<Partial<Sender>>({});
  const [
    deleteConfirmationOpened,
    { open: openDeleteConfirmation, close: closeDeleteConfirmation },
  ] = useDisclosure(false);
  const [senderToDelete, setSenderToDelete] = useState<number | null>(null);
  const [senderFormOpened, { open: openSenderForm, close: closeSenderForm }] = useDisclosure(false);
  const [
    defaultSenderModalOpened,
    { open: openDefaultSenderModal, close: closeDefaultSenderModal },
  ] = useDisclosure(false);
  const [selectedSender, setSelectedSender] = useState<Sender | null>(null);

  useEffect(() => {
    if (locationId) {
      loadSenders();
    }
  }, [locationId]);

  const loadSenders = async () => {
    try {
      if (!locationId) {
        throw new Error('Location ID is missing');
      }
      const response = await searchSenders(locationId, {});
      if (response.status === 'success') {
        const sortedSenders = (response.data || []).sort(
          (a: Sender, b: Sender) => (a.id ?? 0) - (b.id ?? 0)
        );
        setSenders(sortedSenders);
      } else {
        notifications.show({
          title: 'Error',
          message: response.message || 'Failed to fetch senders',
          color: 'red',
        });
      }
    } catch (error: any) {
      notifications.show({
        title: 'Error',
        message: error.message || 'Failed to fetch senders',
        color: 'red',
      });
    }
  };

  const handleSetDefaultSender = async () => {
    if (!selectedSender) {
      return;
    }

    try {
      // Reset all senders' is_default to false and set the selected one to true
      const updatedSenders = senders.map((s) => ({
        ...s,
        is_default: s.id === selectedSender.id,
      }));

      setSenders(updatedSenders);
      setEditedData(selectedSender);
      closeDefaultSenderModal();

      notifications.show({
        title: 'Success',
        message: `${selectedSender.first_name} ${selectedSender.last_name} is now the default sender.`,
        color: 'green',
      });

      // Persist the change to the backend
      await updateSender({ ...selectedSender, is_default: true });
    } catch (error: any) {
      notifications.show({
        title: 'Error',
        message: error.message || 'Failed to update default sender',
        color: 'red',
      });
    }
  };

  const handleDeleteSender = async (senderId: number) => {
    try {
      await deleteSender(locationId!, senderId);
      setSenders((prev) => prev.filter((s) => s.id !== senderId));
      notifications.show({
        title: 'Success',
        message: 'Sender has been successfully deleted',
        color: 'green',
      });
    } catch (error: any) {
      notifications.show({
        title: 'Error',
        message: error.message || 'Failed to delete sender',
        color: 'red',
      });
    }
  };

  const handleEditClick = (sender: Sender) => {
    setEditingSender(sender.id ?? null);
    setEditedData(sender);
  };

  const handleCancelEdit = () => {
    setEditingSender(null);
    setEditedData({});
  };

  const handleSaveEdit = async () => {
    saveEditedSender();
  };

  const saveEditedSender = async () => {
    if (!editingSender) {
      return;
    }

    try {
      const response = await updateSender({
        ...editedData,
        id: editingSender,
      });

      if (response.status === 'success') {
        setSenders((prev) =>
          prev.map((sender) =>
            sender.id === editingSender ? { ...sender, ...editedData } : sender
          )
        );
        setEditingSender(null);
        setEditedData({});
        notifications.show({
          title: 'Success',
          message: 'Sender information has been successfully updated',
          color: 'green',
        });
      } else {
        notifications.show({
          title: 'Error',
          message: response.message || 'Failed to update sender',
          color: 'red',
        });
      }
    } catch (error: any) {
      notifications.show({
        title: 'Error',
        message: error.message || 'Failed to update sender',
        color: 'red',
      });
    }
  };

  const handleInputChange =
    (field: keyof Sender) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setEditedData((prev) => ({
        ...prev,
        [field]: event.target.value,
      }));
    };

  const handleSenderFormSuccess = () => {
    loadSenders();
    notifications.show({
      title: 'Success',
      message: 'New sender has been successfully created',
      color: 'green',
    });
    closeSenderForm();
  };

  // Overlay-based cell rendering
  const renderCell = (sender: Sender, field: keyof Sender) => {
    const isEditing = editingSender === sender.id;
    const displayValue = sender[field] ?? ''; // Ensure it's always a string

    if (field === 'is_default') {
      return (
        <Radio
          checked={sender.is_default}
          onChange={() => {
            if (!sender.is_default) {
              setSelectedSender(sender);
              openDefaultSenderModal();
            }
          }}
        />
      );
    }

    if (field === 'id') {
      return sender.id;
    }

    const editValue = editedData[field] ?? '';

    return (
      <div style={{ position: 'relative', display: 'flex', alignItems: 'center', width: '100%' }}>
        <div
          style={{ flex: 1, visibility: isEditing ? 'hidden' : 'visible', whiteSpace: 'nowrap' }}
        >
          {displayValue || <span>&nbsp;</span>}
        </div>

        {isEditing && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <TextInput
              size="xs"
              style={{ flex: 1, width: '100%' }}
              value={editValue}
              onChange={handleInputChange(field)}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <Group>
        <Group justify="space-between" align="center">
          <Text size="lg" w={500}>
            Senders
          </Text>
          <Tooltip label="Create User">
            <ActionIcon size="lg" radius={20} onClick={openSenderForm}>
              <IconPlus size={22} />
            </ActionIcon>
          </Tooltip>
        </Group>

        <Paper
          withBorder
          shadow="sm"
          radius="md"
          style={{ width: '100%', minWidth: '100%', overflow: 'auto' }}
        >
          {/* We're keeping tableLayout: 'auto' by not overriding it,
              so columns won't be "fixed-width." */}
          <Table striped highlightOnHover withColumnBorders>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>Default</Table.Th>
                <Table.Th>Id</Table.Th>
                <Table.Th>Business Name</Table.Th>
                <Table.Th>First Name</Table.Th>
                <Table.Th>Last Name</Table.Th>
                <Table.Th>Address 1</Table.Th>
                <Table.Th>Address 2</Table.Th>
                <Table.Th>City</Table.Th>
                <Table.Th>State</Table.Th>
                <Table.Th>Zip Code</Table.Th>
                <Table.Th>Actions</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {senders.map((sender) => (
                <Table.Tr key={sender.id}>
                  <Table.Td>{renderCell(sender, 'is_default')}</Table.Td>
                  <Table.Td>{renderCell(sender, 'id')}</Table.Td>
                  <Table.Td>{renderCell(sender, 'business_name')}</Table.Td>
                  <Table.Td>{renderCell(sender, 'first_name')}</Table.Td>
                  <Table.Td>{renderCell(sender, 'last_name')}</Table.Td>
                  <Table.Td>{renderCell(sender, 'address1')}</Table.Td>
                  <Table.Td>{renderCell(sender, 'address2')}</Table.Td>
                  <Table.Td>{renderCell(sender, 'city')}</Table.Td>
                  <Table.Td>{renderCell(sender, 'state')}</Table.Td>
                  <Table.Td>{renderCell(sender, 'postal_code')}</Table.Td>
                  <Table.Td>
                    <Group gap="xs" justify="flex-start" wrap="nowrap">
                      {editingSender === sender.id ? (
                        <>
                          <Tooltip label="Cancel">
                            <ActionIcon color="gray" onClick={handleCancelEdit}>
                              <IconX size={18} />
                            </ActionIcon>
                          </Tooltip>
                          <Tooltip label="Save">
                            <ActionIcon color="green" onClick={handleSaveEdit}>
                              <IconCheck size={18} />
                            </ActionIcon>
                          </Tooltip>
                        </>
                      ) : (
                        <>
                          <Tooltip label="Edit">
                            <ActionIcon color="blue" onClick={() => handleEditClick(sender)}>
                              <IconEdit size={18} />
                            </ActionIcon>
                          </Tooltip>
                          <Tooltip label="Delete">
                            <ActionIcon
                              color="red"
                              onClick={() => {
                                setSenderToDelete(sender.id || null);
                                openDeleteConfirmation();
                              }}
                            >
                              <IconTrash size={18} />
                            </ActionIcon>
                          </Tooltip>
                        </>
                      )}
                    </Group>
                  </Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        </Paper>

        <SenderForm
          opened={senderFormOpened}
          onClose={closeSenderForm}
          onSuccess={handleSenderFormSuccess}
          onError={(error) => {
            notifications.show({
              title: 'Error',
              message: error.message || 'Failed to create sender',
              color: 'red',
            });
          }}
        />

        <Modal
          opened={deleteConfirmationOpened}
          onClose={closeDeleteConfirmation}
          title="Confirm Deletion"
          centered
        >
          <Text>Are you sure you want to delete this sender?</Text>
          <Group mt="md" justify="flex-end">
            <Button variant="default" onClick={closeDeleteConfirmation}>
              Cancel
            </Button>
            <Button
              color="red"
              onClick={() => {
                if (senderToDelete !== null) {
                  handleDeleteSender(senderToDelete);
                }
                closeDeleteConfirmation();
              }}
            >
              Yes, Delete
            </Button>
          </Group>
        </Modal>

        <Modal
          opened={defaultSenderModalOpened}
          onClose={closeDefaultSenderModal}
          title="Set Default Sender"
          centered
        >
          <Text>
            Set {selectedSender?.first_name} {selectedSender?.last_name} as the default Sender?
          </Text>
          <Group mt="md" justify="flex-end">
            <Button variant="default" onClick={closeDefaultSenderModal}>
              Cancel
            </Button>
            <Button color="blue" onClick={handleSetDefaultSender}>
              Yes, Set Default
            </Button>
          </Group>
        </Modal>
      </Group>
    </>
  );
};

export default SendersTable;
