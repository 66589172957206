// src/pages/reports/ReportsPage.tsx
import { Container, Title } from '@mantine/core';

const ReportsPage = () => {
  return (
    <Container size="sm" style={{ textAlign: 'center', marginTop: '5rem' }}>
      <Title order={1}>Reports Page</Title>
    </Container>
  );
};

export default ReportsPage;
