import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { AppShell, Burger, Group, NavLink, ScrollArea, Stack, Tabs, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import ColorSchemeToggle from '@/components/ColorSchemeToggle/ColorSchemeToggle';

interface MenuItem {
  label: string;
  path: string;
}

interface DashboardLayoutProps {
  menuItems: MenuItem[];
  tabs?: MenuItem[];
}

const DashboardLayout: React.FC<DashboardLayoutProps> = ({ menuItems, tabs = [] }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [opened, { toggle, close }] = useDisclosure(false);

  useEffect(() => {
    close(); // Close menu when route changes
  }, [location.pathname, close]);

  return (
    <AppShell
      padding="md"
      header={{ height: 80 }}
      navbar={{ width: 250, breakpoint: 'sm', collapsed: { mobile: !opened } }}
    >
      {/* Header */}
      <AppShell.Header withBorder>
        <Group h="100%" px="md" justify="space-between">
          <Group>
            <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
            <Text fw={700}>RunIt Postcards</Text>
          </Group>
          <Tabs
            variant="outline"
            value={tabs.find((tab) => location.pathname.startsWith(tab.path))?.label || ''}
            onChange={(label) => {
              const tab = tabs.find((tab) => tab.label === label);
              if (tab) {
                navigate(tab.path);
              }
            }}
          >
            <Tabs.List>
              {tabs.map((tab) => (
                <Tabs.Tab key={tab.label} value={tab.label}>
                  {tab.label}
                </Tabs.Tab>
              ))}
            </Tabs.List>
          </Tabs>
          <ColorSchemeToggle />
        </Group>
      </AppShell.Header>

      {/* Navbar */}
      <AppShell.Navbar p="md">
        <ScrollArea style={{ height: '100%' }}>
          <Stack gap="sm" mt="md">
            {menuItems.map((item) => (
              <NavLink
                key={item.label}
                label={item.label}
                active={location.pathname === item.path}
                onClick={() => {
                  navigate(item.path);
                  close(); // Close menu when link is clicked
                }}
              />
            ))}
          </Stack>
        </ScrollArea>
      </AppShell.Navbar>

      {/* Main Content with Routes */}
      <AppShell.Main>
        <Outlet />
      </AppShell.Main>
    </AppShell>
  );
};

export default DashboardLayout;
