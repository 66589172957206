import {
  Combobox,
  Group,
  Image,
  Input,
  InputWrapper,
  Text,
  UnstyledButton,
  useCombobox,
} from '@mantine/core';

interface HandwritingOption {
  id: string;
  name: string;
  imageUrl: string;
}

interface SelectHandwritingProps {
  data: HandwritingOption[];
  value: string | null;
  onChange: (value: string | null) => void;
  label?: string;
  placeholder?: string;
  required?: boolean;
}

function HandwritingOptionComponent({
  option,
  isActive,
}: {
  option: HandwritingOption;
  isActive: boolean;
}) {
  return (
    <Combobox.Option value={option.id} active={isActive}>
      <Group gap="sm" align="center" style={{ padding: '4px 8px' }}>
        <Image
          src={option.imageUrl}
          alt={option.name}
          height={30}
          style={{
            flex: 1,
            objectFit: 'contain',
            borderRadius: 'var(--mantine-radius-sm)',
            display: 'block',
          }}
        />
        <Text size="sm" truncate="end" style={{ flex: 1 }}>
          {option.name}
        </Text>
      </Group>
    </Combobox.Option>
  );
}

export default function SelectHandwriting({
  data,
  value,
  onChange,
  label,
  placeholder,
  required,
}: SelectHandwritingProps) {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.selectActiveOption(),
  });

  const selected = data.find((item) => item.id === value);

  return (
    <Combobox
      store={combobox}
      withinPortal={false}
      onOptionSubmit={(val: string) => {
        onChange(val);
        combobox.closeDropdown();
      }}
    >
      <InputWrapper label={label} required={required}>
        <Combobox.Target>
          <UnstyledButton onClick={() => combobox.toggleDropdown()} style={{ width: '100%' }}>
            <Input
              readOnly
              rightSection={<Combobox.Chevron />}
              rightSectionPointerEvents="none"
              value={selected ? selected.name : ''}
              placeholder={placeholder || 'Select handwriting style'}
            />
          </UnstyledButton>
        </Combobox.Target>
      </InputWrapper>

      <Combobox.Dropdown>
        <Combobox.Options style={{ maxHeight: '18em', overflowY: 'auto' }}>
          {data.map((option) => (
            <HandwritingOptionComponent
              key={option.id}
              option={option}
              isActive={combobox.selectedOptionIndex === data.indexOf(option)}
            />
          ))}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}
